import { Button, Checkbox, Form, Input, Flex, Divider, message } from "antd";
import {
  LockOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ReactComponent as GoogleIcon } from "../../assets/google-icon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/facebook-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/Vector2.svg";
import { useAuth } from "../../Context/AuthProvider";

function errorMessage(message) {
  return (
    <>
      <ErrorIcon className="inline-block mb-0.5" />
      <span className="inline-block font-medium	pl-1">{message}</span>
    </>
  );
}

function LoginForm() {
  const {
    formData,
    handleLogin,
    handleRegisterInputChange,
    isAuthenticating,
    handleContinueGoogle,
    handleContinueFacebook,
    handleContinueApple,
  } = useAuth();

  return (
    <>
      <h2 className="block text-center mx-auto font-semibold text-2xl mb-0.5">
        Welcome to Agteq <span style={{ color: "#16a34a" }}>Bloom</span>
      </h2>
      <p className="text-center font-light text-base mb-4 text-neutral-500">
        Fill out the form with your information
      </p>
      <div className="login-form w-80 md:w-96 lg:w-96 shadow-md	p-6	rounded-2xl	border	border-slate-200 bg-white">
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: errorMessage("Please enter your Email address!"),
              },
            ]}
          >
            <Input
              name="email"
              className="p-2.5 rounded-lg mb-2"
              prefix={<UserOutlined />}
              placeholder="Email Address"
              onChange={handleRegisterInputChange}
              value={formData.email}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: errorMessage("Please enter your password!"),
              },
            ]}
          >
            <Input.Password
              name="password"
              className="p-2.5 rounded-lg mb-2"
              placeholder="Password"
              prefix={<LockOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              onChange={handleRegisterInputChange}
              value={formData.passowrd}
            />
          </Form.Item>
          <Form.Item>
            <Flex justify="space-between" align="center">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Flex>
          </Form.Item>

          <Form.Item>
            <Button
              className="px-4 py-5"
              block
              type="primary"
              htmlType="submit"
              onClick={handleLogin}
              disabled={isAuthenticating}
            >
              Login
            </Button>
            <div className="text-center my-4 text-neutral-800">
              <Link to={"/forgot_password"}>Forgot password?</Link>
            </div>
            <Divider plain>Or</Divider>
            <Button
              className="third-party-auth text-neutral-800 text-sm mb-4 py-5 px-4 rounded-lg"
              size="large"
              icon={<GoogleIcon />}
              disabled={isAuthenticating}
              onClick={handleContinueGoogle}
              htmlType="button"
            >
              Continue with Google
            </Button>
            <Button
              className="third-party-auth text-neutral-800 text-sm mb-4 py-5 px-4 rounded-lg"
              size="large"
              icon={<FacebookIcon />}
              disabled={isAuthenticating}
              onClick={handleContinueFacebook}
              htmlType="button"
            >
              Continue with Facebook
            </Button>
            <Button
              className="third-party-auth text-neutral-800 text-sm py-5 px-4 rounded-lg"
              size="large"
              htmlType="button"
              icon={
                <svg
                  fill="#000000"
                  height="18px"
                  width="18px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22.773 22.773"
                  className="mb-1"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573 c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z"></path>{" "}
                        <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334 c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0 c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019 c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464 c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648 c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z"></path>{" "}
                      </g>{" "}
                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                      <g> </g> <g> </g> <g> </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              }
              disabled={isAuthenticating}
              onClick={handleContinueApple}
            >
              Continue with Apple
            </Button>
            {/* <p className="text-neutral-500 text-xs text-center">
              Protected by Google’s reCAPTCHA. By signing up, you agree to
              Agteq’s Terms & Privacy Policy
            </p> */}
          </Form.Item>

          <Form.Item>
            <Divider />
            <p className="text-neutral-500 text-sm text-center">
              Don’t have an account?{" "}
              <Link
                to="/register"
                style={{ color: "#16a34a" }}
                className="font-semibold"
              >
                Sign up
              </Link>
            </p>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default LoginForm;
