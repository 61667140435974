import { useMutation, useQuery } from "react-query";
import { getPrecisionFieldSessions } from "../../Services/apiBloom";

export function useGetFieldSessions() {
  const {
    mutate: fieldSessionsData,
    isLoading: isFieldSessionsData,
    error: errorFieldSessionsData,
  } = useMutation({
    mutationFn: getPrecisionFieldSessions,
  });

  return {
    fieldSessionsData,
    isFieldSessionsData,
    errorFieldSessionsData,
  };
}
