import { createContext, useContext, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { jwtDecode } from "jwt-decode";
import { usePrecisionSessions } from "../Features/Chat/usePrecisionSessions";
import { useFarm } from "../Features/Chat/useFarm";

const ChatContext = createContext();

const initialState = {
  chatMode: "",
  sideBarMode: "Farm",
  bloomUser: null,
  season: "",
  year: "",
  farmFormData: {
    farm_name: "",
    country: 0,
    countryText: "",
    province_state: 0,
    province_stateText: "",
    city: 0,
    county: 0,
    countyText: "",
    planted_area: 0,
    postal_address: "",
    lld: "",
    quarter_section: "",
    section: "",
    township: "",
    range: "",
    meridian: "",
  },
  fieldFormData: {
    farm: "",
    field_name: "",
    soil_texture: 0,
    irregation_type: 0,
    planted_area: 0,
    approximate_crop_height: 0,
    crop_name: 0,
    crop_stage: 0,
    weeds: [],
  },
  farms: [],
  farmFields: [],
  currentFarm: 0,
  currentField: 0,
  generalSessions: [],
  currentGeneralHash: "",
  deactivateOtp: "",
  precisionSessions: [],
  fieldSessions: [],
  currentPrecisionHash: "",
  backUpFieldData: {},
  stringLog: "",
  templateSlug: "",
  otherTempSlug: "",
  generalLenghtCatcher: undefined,
  precisionLenghtCatcher: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case "selectChat":
      return { ...state, chatMode: action.payload };
    case "selectSideBarMode":
      return { ...state, sideBarMode: action.payload };
    case "setBloomUser":
      return { ...state, bloomUser: action.payload };
    case "handleFarmInputChange":
      return {
        ...state,
        farmFormData: {
          ...state.farmFormData,
          [action.payload.name]: action.payload.value,
        },
      };
    case "handleFieldInputChange":
      return {
        ...state,
        fieldFormData: {
          ...state.fieldFormData,
          [action.payload.name]: action.payload.value,
        },
      };
    case "addWeed":
      return {
        ...state,
        fieldFormData: {
          ...state.fieldFormData,
          weeds: [
            ...state.fieldFormData.weeds,
            {
              weed_name: 0,
              weed_density: 0,
              leaves_number: 0,
              height: 0,
              flowering: false,
            },
          ],
        },
      };
    case "updateWeed":
      const { index, name, value } = action.payload;
      return {
        ...state,
        fieldFormData: {
          ...state.fieldFormData,
          weeds: state.fieldFormData.weeds.map(
            (weed, i) => (i === index ? { ...weed, [name]: value } : weed) // Only update the specific weed, preserve others
          ),
        },
      };
    case "deleteWeed":
      return {
        ...state,
        fieldFormData: {
          ...state.fieldFormData,
          weeds: state.fieldFormData.weeds.filter(
            (_, idx) => idx !== action.payload
          ),
        },
      };
    case "discardFarm":
      return {
        ...state,
        farmFormData: {
          farm_name: "",
          country: 0,
          province_state: "",
          city: "",
          county: "",
          planted_area: 0,
          postal_address: "",
          lld: "",
          quarter_section: "",
          section: "",
          township: "",
          range: "",
          meridian: "",
        },
      };
    case "discardField":
      return {
        ...state,
        fieldFormData: {
          ...state.fieldFormData,
          field_name: "",
          soil_texture: 0,
          irregation_type: 0,
          planted_area: 0,
          approximate_crop_height: 0,
          crop_name: 0,
          crop_id: 0,
          crop_stage: 0,
          weeds: [],
        },
      };
    case "getFarms":
      return {
        ...state,
        farms: action.payload,
      };
    case "selectFarm":
      return {
        ...state,
        currentFarm: action.payload,
      };
    case "getFarmFields":
      return {
        ...state,
        farmFields: action.payload,
      };
    case "selectField":
      return {
        ...state,
        currentField: action.payload,
      };
    case "updateFarms":
      return {
        ...state,
        farms: state.farms.map((farm) => {
          if (farm.id === action.payload.id) {
            return { ...farm, ...action.payload.data };
          }
          return farm;
        }),
      };
    case "updateFarmField":
      return {
        ...state,
        farmFields: state.farmFields.map((field) => {
          if (field.id === action.payload.id) {
            return { ...field, ...action.payload.data };
          }
          return field;
        }),
      };
    case "setGeneralSessions":
      return {
        ...state,
        generalSessions: action.payload,
      };
    case "setCurrentGeneralHash":
      return {
        ...state,
        currentGeneralHash: action.payload,
      };
    case "handleEditBloomUser":
      return {
        ...state,
        bloomUser: {
          ...state.bloomUser,
          [action.payload.item]: action.payload.value,
        },
      };
    case "tempGeneralSessionsUpdate":
      return {
        ...state,
        generalSessions: [...state.generalSessions, action.payload],
      };
    case "tempGeneralSessionsDelete":
      return {
        ...state,
        generalSessions: state.generalSessions.filter(
          (session) => session.slug !== action.payload
        ),
      };
    case "tempGeneralSessionsEditTitle":
      return {
        ...state,
        generalSessions: state.generalSessions.map((session) =>
          session.slug === action.payload.slug
            ? { ...session, session_title: action.payload.newTitle }
            : session
        ),
      };

    case "activateTempGeneralSession":
      return {
        ...state,
        generalSessions: state.generalSessions.map((session) =>
          session.slug === action.payload.slug ? action.payload : session
        ),
      };
    case "activateTempFieldSession":
      return {
        ...state,
        fieldSessions: state.fieldSessions.map((session) =>
          session.slug === action.payload.slug ? action.payload : session
        ),
      };
    case "storeDeactiveOtp":
      return {
        ...state,
        deactivateOtp: action.payload,
      };
    case "setCurrentPrecisionHash":
      return {
        ...state,
        currentPrecisionHash: action.payload,
      };
    case "setPrecisionSessions":
      return {
        ...state,
        precisionSessions: action.payload,
      };
    case "tempPrecisionSessionsUpdate":
      return {
        ...state,
        precisionSessions: [...state.precisionSessions, action.payload],
      };
    case "updateSeasonYear":
      return {
        ...state,
        season: action.payload.season,
        year: action.payload.year,
      };
    case "getBackUpFieldData":
      return {
        ...state,
        backUpFieldData: action.payload,
      };
    case "getStringLog":
      return {
        ...state,
        stringLog: action.payload,
      };
    case "setTemplateSlug":
      return {
        ...state,
        templateSlug: action.payload,
      };
    case "generalLengthCatcher":
      return {
        ...state,
        generalLenghtCatcher: action.payload,
      };
    case "precisionFieldLengthCatcher":
      return {
        ...state,
        precisionLenghtCatcher: action.payload,
      };
    case "setOtherTempSlug":
      return {
        ...state,
        otherTempSlug: action.payload,
      };
    case "setTempFieldSessions":
      return {
        ...state,
        fieldSessions: action.payload,
      };
    case "tempFieldSessionsUpdate":
      return {
        ...state,
        fieldSessions: [...state.fieldSessions, action.payload],
      };
    default:
      throw new Error("Unknown action");
  }
}

function ChatProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    chatMode,
    sideBarMode,
    bloomUser,
    farmFormData,
    fieldFormData,
    farms,
    farmFields,
    currentFarm,
    currentField,
    season,
    year,
    generalSessions,
    currentGeneralHash,
    deactivateOtp,
    currentPrecisionHash,
    backUpFieldData,
    stringLog,
    templateSlug,
    generalLenghtCatcher,
    precisionLenghtCatcher,
    otherTempSlug,
    fieldSessions,
  } = state;

  const { userToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const { farmData, isFarmData, errorFarmData } = useFarm();

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch({ type: "selectChat", payload: "" });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userToken) {
      dispatch({ type: "setBloomUser", payload: jwtDecode(userToken.access) });
    }
  }, [userToken]);

  const handleFarmInputChange = (name, value) => {
    dispatch({ type: "handleFarmInputChange", payload: { name, value } });
  };

  const handleFieldInputChange = (name, value) => {
    dispatch({ type: "handleFieldInputChange", payload: { name, value } });
  };

  const updateWeed = (index, name, value) => {
    dispatch({
      type: "updateWeed",
      payload: { index, name, value },
    });
  };

  const {
    precisionSessionsData,
    isPrecisionSessionsData,
    erroPrecisionSessionsData,
  } = usePrecisionSessions();

  useEffect(() => {
    if (bloomUser) {
      precisionSessionsData(
        { season, year },
        {
          onSuccess: (data) => {
            dispatch({
              type: "setPrecisionSessions",
              payload:
                isPrecisionSessionsData || erroPrecisionSessionsData
                  ? []
                  : data,
            });
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    if (chatMode === "general" && localStorage.getItem("generalHash")) {
      dispatch({
        type: "setCurrentGeneralHash",
        payload: localStorage.getItem("generalHash"),
      });
      navigate(`/c/general/${localStorage.getItem("generalHash")}`, {
        replace: true,
      });
    } else if (
      chatMode === "precision" &&
      localStorage.getItem("precisionHash")
    ) {
      dispatch({
        type: "setCurrentPrecisionHash",
        payload: localStorage.getItem("precisionHash"),
      });
      navigate(`/c/precision/${localStorage.getItem("precisionHash")}`, {
        replace: true,
      });
    }
  }, [chatMode]);

  useEffect(() => {
    if (bloomUser) {
      farmData(
        { farm_id: currentFarm },
        {
          onSuccess: (data) => {
            handleFarmInputChange(
              "country",
              !data.municipal_district
                ? 0
                : data.municipal_district.province.country.id
            );
            handleFarmInputChange(
              "countryText",
              !data.municipal_district
                ? ""
                : data.municipal_district.province.country.country_name
            );
            handleFarmInputChange(
              "province_state",
              !data.municipal_district ? 0 : data.municipal_district.province.id
            );
            handleFarmInputChange(
              "province_stateText",
              !data.municipal_district
                ? ""
                : data.municipal_district.province.name
            );
            handleFarmInputChange(
              "county",
              !data.municipal_district ? 0 : data.municipal_district.id
            );
            handleFarmInputChange(
              "countyText",
              !data.municipal_district ? "" : data.municipal_district.name
            );
          },
        }
      );
    }
  }, [currentFarm]);

  const getSeason = (month) => {
    if (month >= 2 && month <= 4) return "spring";
    if (month >= 5 && month <= 7) return "summer";
    if (month >= 8 && month <= 11) return "fall";
    return "winter";
  };

  useEffect(() => {
    if (bloomUser && localStorage.getItem("userToken")) {
      if (localStorage.getItem("selectedSeason")) {
        dispatch({
          type: "updateSeasonYear",
          payload: JSON.parse(localStorage.getItem("selectedSeason")),
        });
      } else {
        const currentDate = jwtDecode(
          JSON.stringify(localStorage.getItem("userToken"))
        ).registered_date;

        const createdDate = new Date(currentDate);
        let cYear = createdDate.getFullYear();
        let cMonth = createdDate.getMonth() + 1;
        let cSeason = getSeason(cMonth);
        dispatch({
          type: "updateSeasonYear",
          payload: { season: cSeason, year: cYear },
        });
      }
    }
  }, [bloomUser]);

  const handleEditBloomUser = (item, value) => {
    dispatch({ type: "handleEditBloomUser", payload: { item, value } });
  };

  return (
    <ChatContext.Provider
      value={{
        dispatch,
        chatMode,
        sideBarMode,
        bloomUser,
        farmFormData,
        fieldFormData,
        handleFarmInputChange,
        handleFieldInputChange,
        updateWeed,
        currentField,
        farmFields,
        currentFarm,
        farms,
        season,
        year,
        generalSessions,
        currentGeneralHash,
        handleEditBloomUser,
        deactivateOtp,
        currentPrecisionHash,
        backUpFieldData,
        stringLog,
        templateSlug,
        generalLenghtCatcher,
        precisionLenghtCatcher,
        otherTempSlug,
        fieldSessions,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

function useChat() {
  const context = useContext(ChatContext);
  if (context === undefined)
    throw new Error("ChatContext was used outside of Chat Provider");

  return context;
}

export { ChatProvider, useChat };
