import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Segmented,
  Menu,
  Avatar,
  Tabs,
  Collapse,
  Form,
  Typography,
  Divider,
  Dropdown,
  message,
  Space,
  Modal,
  Input,
} from "antd";
import {
  SidebarCollapse,
  ChatBubbleEmpty,
  BubbleStar,
  Plus,
  ChatLines,
  MoreHoriz,
  Farm,
  EditPencil,
  Trash,
} from "iconoir-react";
import BloomLogo from "../assets/bloom-logo.png";
import { useEffect, useState } from "react";
import { useChat } from "../Context/ChatProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useCrops } from "../Features/Chat/useCrops";
import { useWeeds } from "../Features/Chat/useWeeds";
import { useWeeedDensities } from "../Features/Chat/useWeedDensities";
import { useSoilTexture } from "../Features/Chat/useSoilTypes";
import { useIrregationTypes } from "../Features/Chat/useIrregationTypes";
import { v4 } from "uuid";
import { useCropGroups } from "../Features/Chat/useCropGroups";
import LeftPrecisionMenu from "./LeftPrecisionMenu ";
import { useFarmFields } from "../Features/Chat/useFarmFields";
import { useFarmField } from "../Features/Chat/useFarmField";
import { useCreateFarm } from "../Features/Chat/useCreateFarm";
import { useDeleteGeneralSession } from "../Features/Chat/useDeleteGeneralSession";
import { useEditGeneralSessionTitle } from "../Features/Chat/useEditGeneralSessionTitle";
import { useDeletePrecisionSession } from "../Features/Chat/useDeletePrecisionSession";
import { useEditPrecisionSessionTitle } from "../Features/Chat/useEditPrecisionSessionTitle";
const { Text, Title } = Typography;
const { Panel } = Collapse;
function ChatDrawer({ openDrawer, setOpenDrawer, showEditDrawer }) {
  const onClose = () => {
    setOpenDrawer(false);
  };
  const {
    chatMode,
    dispatch,
    farmFormData,
    fieldFormData,
    farms,
    farmFields,
    handleFarmInputChange,
    year,
    season,
    currentFarm,
    currentField,
    handleFieldInputChange,
    generalSessions,
    templateSlug,
  } = useChat();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const { hash } = useParams();

  const { farmFieldsData, isFarmFieldsData, errorFarmFieldsData } =
    useFarmFields();
  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();
  const [selectedPKeys, setSelectedPKeys] = useState([]);

  const {
    deleteGeneralSessionData,
    isDeleteGeneralSessionData,
    errorDeleteGeneralSessionData,
  } = useDeleteGeneralSession();

  const {
    editGeneralSessionTitleData,
    isEditGeneralSessionTitleData,
    errorEditGeneralSessionTitleData,
  } = useEditGeneralSessionTitle();

  const {
    deletePrecisionSessionData,
    isDeletePrecisionSessionData,
    errorDeletePrecisionSessionData,
  } = useDeletePrecisionSession();

  const {
    editPrecisionSessionTitleData,
    isEditPrecisionSessionTitleData,
    errorEditPrecisionSessionTitleData,
  } = useEditPrecisionSessionTitle();

  const chatModeSelectorSegment = (value) => {
    if (value === "Precision Chat") {
      navigate("/c/precision");
      dispatch({ type: "selectChat", payload: "precision" });
    } else {
      navigate("/c/general");
      dispatch({ type: "selectChat", payload: "general" });
    }
  };
  const tabName = (e) => {
    if (e === "Chat") {
      return null;
    } else {
      dispatch({ type: "selectSideBarMode", payload: e });
    }
  };

  useEffect(() => {
    setFarmsItems(farms);
  }, [farms]);

  const { soilTextureData, isSoilTextureData, errorSoilTextureData } =
    useSoilTexture();
  const {
    irregationTypesData,
    isIrregationTypesData,
    errorIrregationTypesData,
  } = useIrregationTypes();

  const { cropGroupsData, isCropGroups, errorCropGroups } = useCropGroups();

  function findCropById(id) {
    // Recursive function to search for the crop by id
    const search = (crops) => {
      if (!isCropGroups) {
        for (let category of crops) {
          // Iterate through the crops array
          for (let crop of category?.crops) {
            if (crop.id === id) {
              return crop.crop_name; // Return the crop_name if id matches
            }

            // If there are children, search recursively
            if (crop.children.length > 0) {
              const result = search([{ crops: crop.children }]);
              if (result) return result; // Return if found in children
            }
          }
        }
      }
      return null; // Return null if not found
    };

    return search(cropGroupsData);
  }

  const { cropData, isCropData } = useCrops();
  const [previewStage, setPreviewStages] = useState();

  useEffect(() => {
    cropData(
      { crop_id: fieldFormData.crop_name },
      {
        onSuccess: (data) => {
          setPreviewStages(data?.crop_stages);
        },
        onError: () => {
          return null;
        },
      }
    );
  }, [fieldFormData.crop_name, cropData]);

  const { weedsData, isWeeds, errorWeedsData } = useWeeds();
  const { weedDensitiesData, isWeedDensities, errorWeedDensitiesData } =
    useWeeedDensities();

  function ModalLabel({ text }) {
    return <p>This will delete {<span className="font-bold">{text}</span>}</p>;
  }

  const [selectedRenaming, setSelectedRenaming] = useState("");
  const [newSessionTitle, setNewSessionTitle] = useState("");

  const deleteGeneralSessionHandler = (slug) => {
    deleteGeneralSessionData(
      { slug: slug },
      {
        onSuccess: (data) => {
          dispatch({ type: "tempGeneralSessionsDelete", payload: slug });
          if (localStorage.getItem("generalHash")) {
            if (localStorage.getItem("generalHash") === slug) {
              localStorage.removeItem("generalHash");
              navigate("/c/general");
            }
          }
          message.success("Chat deleted");
        },
        onError: () => {
          message.error(
            "Something went wrong while deleting the chat, Please try again!"
          );
        },
      }
    );
  };

  const editGeneralSessionHandler = (e, slug, session_title) => {
    e.stopPropagation();
    if (session_title === "") {
      return false;
    } else {
      const newTitle =
        session_title?.length > 19
          ? session_title.substring(0, 19) + "..."
          : session_title;

      editGeneralSessionTitleData(
        { slug: slug, session_title: newTitle },
        {
          onSuccess: (data) => {
            dispatch({
              type: "tempGeneralSessionsEditTitle",
              payload: { slug: slug, newTitle: newTitle },
            });
            setSelectedRenaming("");
            setNewSessionTitle("");
          },
          onError: () =>
            message.error(
              "Something went wrong while renaming the chat, Please try again!"
            ),
        }
      );
    }
  };

  const deletePrecisionSessionHandler = (slug) => {
    deletePrecisionSessionData(
      { slug: slug },
      {
        onSuccess: (data) => {
          farmFieldsData(
            {
              farm_id: currentFarm,
              season: season,
              year: year,
            },
            {
              onSuccess: (data) => {
                dispatch({ type: "getFarmFields", payload: data.fields });
              },
            }
          );

          if (localStorage.getItem("precisionHash")) {
            if (localStorage.getItem("precisionHash") === slug) {
              localStorage.removeItem("precisionHash");
              navigate("/c/precision");
            }
          }
          message.success("Chat deleted");
        },
        onError: () =>
          message.error(
            "Something went wrong while deleting the chat, Please try again!"
          ),
      }
    );
  };

  const editPrecisionSessionHandler = (slug, session_title) => {
    if (session_title === "") {
      return false;
    } else {
      const newTitle =
        session_title?.length > 19
          ? session_title.substring(0, 19) + "..."
          : session_title;

      editPrecisionSessionTitleData(
        { slug: slug, session_title: newTitle },
        {
          onSuccess: (data) => {
            farmFieldsData(
              {
                farm_id: currentFarm,
                season: season,
                year: year,
              },
              {
                onSuccess: (data) => {
                  dispatch({ type: "getFarmFields", payload: data.fields });
                },
              }
            );
            setSelectedRenaming("");
            setNewSessionTitle("");
          },
          onError: () =>
            message.error(
              "Something went wrong while renaming the chat, Please try again!"
            ),
        }
      );
    }
  };

  document.body.onclick = () => {
    setSelectedRenaming("");
    setNewSessionTitle("");
  };

  const showDeleteConfirm = (slug, title, deleteHandler) => {
    confirm({
      width: 500, // Adjust width here
      styles: { top: 300 },
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="text-xl">Delete Chat</span>
          <Button
            icon={<CloseOutlined />}
            className="bg-zinc-50"
            type="text"
            onClick={() => Modal.destroyAll()} // This closes the modal
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#888",
            }}
          />
        </div>
      ),
      content: (
        <p className="text-sm text-neutral-500 mb-4 pb-4 border-neutral-300">
          This will delete <span className="font-bold">{title}</span>
        </p>
      ),
      icon: null, // Remove the icon
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-confirm", // Custom class for styling
      style: { top: 250 }, // Custom vertical position
      onOk() {
        deleteHandler(slug);
      },
      afterClose: () =>
        document.removeEventListener("mousedown", handleClickOutside), // Clean up event listener

      okButtonProps: {
        danger: true, // To make the button red
        className: "delete-modal-btn", // Add a custom class
        style: {
          backgroundColor: "#c53c3e", // Custom background color
          borderColor: "#dd4144", // Custom border color
          color: "#fff", // Text color
          fontWeight: "bold", // Custom font weight
          width: "100px", // Set width
          height: "40px", // Set width
        },
      },
      cancelButtonProps: {
        className: "cancel-modal-btn", // Add a custom class
        style: {
          borderColor: "#d9d9d9", // Custom border color for cancel
          color: "#737373", // Text color
          fontWeight: "bold", // Custom font weight
          width: "100px", // Set width for cancel
          height: "40px", // Set width for cancel
        },
      },
    });

    const handleClickOutside = (event) => {
      const modalContainer = document.querySelector(".ant-modal-confirm");
      if (modalContainer && !modalContainer.contains(event.target)) {
        Modal.destroyAll(); // Close the modal if click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  };

  // Example menu items
  const leftGeneralMenuItems = !generalSessions
    ? []
    : generalSessions?.map((session) => ({
        key: session.slug,
        icon: <ChatLines />,
        label: (
          <div
            onClick={() => {
              if (session.slug !== templateSlug) {
                navigate(`/c/general/${session.slug}`, { replace: true });
                localStorage.setItem("generalHash", session.slug);
                setOpenDrawer(false);
              }
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span key={session.slug}>
              {selectedRenaming !== session.slug ? (
                session.session_title
              ) : (
                <Input
                  key={session.slug}
                  id="generalRenameInput"
                  onClick={(e) => e.stopPropagation()}
                  onBlur={() => setSelectedRenaming("")}
                  value={newSessionTitle}
                  onChange={(e) => setNewSessionTitle(e.target.value)}
                  className="bg-transparent"
                  onPressEnter={(e) =>
                    editGeneralSessionHandler(e, session.slug, newSessionTitle)
                  }
                  defaultValue={() => setNewSessionTitle(session.session_title)}
                />
              )}
            </span>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <Space className="p-1">
                        <EditPencil
                          style={{
                            height: "20",
                            width: "20",
                            color: "#737373",
                          }}
                        />
                        <p className="text-sm">Rename</p>
                      </Space>
                    ),
                    key: "0",
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      e.domEvent.preventDefault();
                      setNewSessionTitle(session.session_title);
                      setSelectedRenaming(session.slug);
                      setTimeout(() => {
                        document.getElementById("generalRenameInput")?.focus();
                      }, 40);
                    },
                  },
                  {
                    label: (
                      <Space wrap className="p-1">
                        <Trash
                          style={{
                            height: "20",
                            width: "20",
                            color: "#ef4444",
                          }}
                        />
                        <p className="text-red-500 text-sm">Delete</p>
                      </Space>
                    ),
                    key: "1",
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      e.domEvent.preventDefault();
                      showDeleteConfirm(
                        session.slug,
                        session.session_title,
                        deleteGeneralSessionHandler
                      );
                    },
                  },
                ],
              }}
              trigger={["click"]}
            >
              <MoreHoriz
                style={{ marginLeft: "auto", color: "#737373" }}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          </div>
        ),
        disabled: session.slug === templateSlug ? true : false,
      }));

  const [itemfields, setItemsFields] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState();

  useEffect(() => {
    setItemsFields(
      farmFields?.map((field, i) => ({
        id: field.id,
        feildName: field.field_name,
        questionHistory:
          field.sessions.length === 0
            ? [
                {
                  question: "Start a chat...",
                  key: `${field.id} new chat`,
                },
              ]
            : field.sessions.map((session) => ({
                question: session.session_title,
                key: session.slug,
                disabled: true,
              })),
      }))
    );
  }, [farmFields]);

  const [select, setSelect] = useState(0);

  useEffect(() => {
    setSelect(currentField);
  }, [currentField]);

  const [farmsItems, setFarmsItems] = useState([]);

  useEffect(() => {
    setFarmsItems(farms);
  }, [farms]);

  const menuItemsData = farmsItems?.map((item, i) => ({
    key: i,
    label: item.farm_name,
    id: item.id,
  }));

  const { createFarmData, isCreateFarmData, errorCreateFarmData } =
    useCreateFarm();

  useEffect(() => {
    if (chatMode === "general") {
      setSelectedKeys(hash);
    } else {
      setSelectedPKeys(hash);
    }
  }, [hash]);

  function addFarmHandler() {
    createFarmData(
      {
        data: {
          farm_name: "My Farm",
          country: null,
          province_state: null,
          municipal_district: null,
          farm_area_in_acre: 0,
          farm_address: "",
          postal_address: "",
          lld: "",
          quarter_section: "",
          section: "",
          township: "",
          farm_range: "",
          meridian: "",
          season: season,
          year: year,
        },
      },
      {
        onSuccess: (data) => {
          dispatch({ type: "getFarms", payload: [...farms, data] });
          dispatch({ type: "selectFarm", payload: data.id });

          handleFarmInputChange("farm_name", data.farm_name);
          handleFarmInputChange("country", 0);
          handleFarmInputChange("countryText", "");
          handleFarmInputChange("province_state", 0);
          handleFarmInputChange("province_stateText", "");
          handleFarmInputChange("city", 0);
          handleFarmInputChange("county", 0);
          handleFarmInputChange("planted_area", 0);
          handleFarmInputChange("postal_address", "");
          handleFarmInputChange("lld", "");
          handleFarmInputChange("quarter_section", "");
          handleFarmInputChange("section", "");
          handleFarmInputChange("range", "");
          handleFarmInputChange("meridian", "");
          handleFarmInputChange("township", "");

          farmFieldsData(
            { farm_id: data.id, year: year, season: season },
            {
              onSuccess: (data) => {
                dispatch({ type: "getFarmFields", payload: data.fields });
                dispatch({
                  type: "selectField",
                  payload: data.fields[0] ? data.fields[0].id : null,
                });
                farmFieldData(
                  {
                    farm_field_id: data.fields[0].id,
                    year: year,
                    season: season,
                  },
                  {
                    onSuccess: (data) => {
                      handleFieldInputChange("field_name", "My field");
                      handleFieldInputChange("soil_texture", 0);
                      handleFieldInputChange("irregation_type", 0);
                      handleFieldInputChange("planted_area", 0);
                      handleFieldInputChange("approximate_crop_height", 0);
                      handleFieldInputChange("crop_name", 0);
                      handleFieldInputChange("crop_stage", 0);
                      handleFieldInputChange("farm", data.data.farm);
                      handleFieldInputChange("weeds", []);
                      handleFieldInputChange(
                        "id",
                        data.data.farm_field_crops.id
                      );
                      message.success("Your Farm has been created");
                    },
                  }
                );
                localStorage.removeItem("precisionHash");
                navigate("/c/precision");
              },
            }
          );
        },
        onError: () =>
          message.error(
            "Something went wrong while adding the farm, Please try again!"
          ),
      }
    );
  }

  const farmMenuItems = (
    <>
      <Menu className="max-h-56 overflow-y-auto" style={{ padding: 8 }}>
        <Button
          type="primary"
          icon={<Plus />}
          block
          className="new-chat-btn mb-2"
          onClick={addFarmHandler}
          disabled={isFarmFieldData || isCreateFarmData}
        >
          New Farm
        </Button>
        {menuItemsData
          .slice()
          .reverse()
          ?.map((item) => (
            <Menu.Item
              key={v4()}
              style={{ marginBottom: 8 }}
              className={`flex items-center gap-4  ${
                item.id === currentFarm ? "bg-neutral-100" : null
              }`}
              onClick={() => {
                dispatch({ type: "selectFarm", payload: item.id });
                const farm = farms.filter((farm) => item.id === farm.id)[0];
                handleFarmInputChange("country", 0);
                handleFarmInputChange("countryText", "");
                handleFarmInputChange("county", 0);
                handleFarmInputChange("province_state", 0);
                handleFarmInputChange("province_stateText", "");
                handleFarmInputChange("city", farm.farm_city);
                handleFarmInputChange("farm_name", farm.farm_name);
                handleFarmInputChange("planted_area", farm.farm_area_in_acre);
                handleFarmInputChange("range", farm.farm_range);
                handleFarmInputChange("lld", farm.lld);
                handleFarmInputChange("meridian", farm.meridian);
                handleFarmInputChange("postal_address", farm.postal_address);
                handleFarmInputChange("quarter_section", farm.quarter_section);
                handleFarmInputChange("section", farm.section);
                handleFarmInputChange("township", farm.township);
                handleFarmInputChange("postal_address", farm.postal_address);

                farmFieldsData(
                  { farm_id: item.id, year: year, season: season },
                  {
                    onSuccess: (data) => {
                      dispatch({ type: "getFarmFields", payload: data.fields });
                      dispatch({
                        type: "selectField",
                        payload: data.fields[0] ? data.fields[0].id : null,
                      });
                      farmFieldData(
                        {
                          farm_field_id: data.fields[0].id,
                          year: year,
                          season: season,
                        },
                        {
                          onSuccess: (data) => {
                            handleFieldInputChange("farm", data.data.farm);
                            handleFieldInputChange(
                              "field_name",
                              data.data.field_name ? data.data.field_name : 0
                            );
                            handleFieldInputChange(
                              "soil_texture",
                              data.data.soil_type ? data.data.soil_type : 0
                            );
                            handleFieldInputChange(
                              "irregation_type",
                              data.data.irrigation_type
                                ? data.data.irrigation_type
                                : 0
                            );
                            handleFieldInputChange(
                              "planted_area",
                              data.data.area_in_acre
                            );
                            handleFieldInputChange(
                              "approximate_crop_height",
                              data.data.approximate_crop_height
                            );
                            handleFieldInputChange(
                              "crop_name",
                              data.data.farm_field_crops?.crop
                                ? data.data.farm_field_crops.crop
                                : 0
                            );
                            handleFieldInputChange(
                              "crop_stage",
                              data.data.farm_field_crops?.crop_stage
                                ? data.data.farm_field_crops.crop_stage
                                : 0
                            );
                            handleFieldInputChange(
                              "weeds",
                              data.data.farm_field_crops?.farm_field_weeds
                                ? data.data.farm_field_crops.farm_field_weeds?.map(
                                    (weed) => ({
                                      weed_name: weed.weed,
                                      weed_density: weed.weed_density,
                                      leaves_number:
                                        weed.weed_stage.number_of_leaves,
                                      height: weed.weed_stage.heihgt_in_cm,
                                      flowering: weed.weed_stage.flowering,
                                    })
                                  )
                                : []
                            );
                            handleFieldInputChange(
                              "id",
                              data.data.farm_field_crops?.id
                                ? data.data.farm_field_crops.id
                                : 0
                            );
                            localStorage.removeItem("precisionHash");
                            navigate("/c/precision");
                          },
                        }
                      );
                    },
                    onError: (error) =>
                      message.error(
                        "Connection with the server faild, please try again!"
                      ),
                  }
                );
              }}
            >
              <div className="flex w-9/12 justify-items-center content-center items-center ">
                <div className="justify-self-center	self-center	">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M7.33203 17.332H5.33203V9.33203L9.33203 7.33203L13.332 9.33203V17.332H11.332M7.33203 17.332V12.6654H11.332V17.332M7.33203 17.332H11.332"
                      stroke="#16A34A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 13.332V15.332V17.332"
                      stroke="#16A34A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3346 15.332H16.0013H18.668"
                      stroke="#16A34A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3346 13.332H16.0013H18.668"
                      stroke="#16A34A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-2 text-neutral-800 font-normal	">
                  {item.label}
                </div>
              </div>
            </Menu.Item>
          ))}
      </Menu>
    </>
  );

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems([
      {
        label: "Chat",
        children: (
          <>
            <Button
              type="primary"
              icon={<Plus />}
              size="large"
              block
              className="new-chat-btn mb-5"
              onClick={() => {
                localStorage.removeItem("precisionHash");
                navigate("/c/precision");
                setSelectedPKeys([]);
                setOpenDrawer(false);
              }}
            >
              New Chat
            </Button>
            <div style={{ flex: 1, overflowY: "auto" }}>
              {
                <LeftPrecisionMenu
                  showDeleteConfirm={showDeleteConfirm}
                  setSelectedPKeys={setSelectedPKeys}
                  selectedPKeys={selectedPKeys}
                  itemfields={itemfields}
                  select={select}
                  setSelect={setSelect}
                  setOpenDrawer={setOpenDrawer}
                  deletePrecisionSessionHandler={deletePrecisionSessionHandler}
                  editPrecisionSessionHandler={editPrecisionSessionHandler}
                />
              }
            </div>
            <div style={{ marginTop: "auto" }}>
              <Dropdown
                overlay={farmMenuItems}
                placement="top"
                trigger={["click"]}
              >
                <Button
                  style={{
                    padding: "8px",
                  }}
                  className={`justify-start h-auto w-full rounded-lg ${
                    chatMode !== "general" ? "farm-select-disabled" : null
                  }`}
                  disabled={chatMode === "general"}
                >
                  <Avatar
                    style={{ display: "flex", backgroundColor: "#f5f5f5" }}
                    className="item-center justify-center"
                    size={38}
                    icon={<Farm height={24} width={24} color="#737373" />}
                    shape="square"
                  />
                  <div
                    style={{ flex: "1 0 0" }}
                    className="flex flex-col justify-center items-start"
                  >
                    <p className="text-neutral-500 text-xs">Farms</p>
                    <h3 className="text-neutral-800 text-sm font-semibold">
                      {chatMode === "general"
                        ? "Farm name"
                        : farms?.map((farm) =>
                            farm.id === currentFarm ? farm.farm_name : ""
                          )}
                    </h3>
                  </div>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color="#000000"
                  >
                    <path
                      d="M17 8L12 3L7 8"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M17 16L12 21L7 16"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Button>
              </Dropdown>
            </div>
          </>
        ),
        key: "Chat",
      },
      {
        label: "Farm",
        children: (
          <>
            <Button
              type="primary"
              icon={<EditPencil height={24} width={24} />}
              block
              size="large"
              className="edit-field-farm-btn mb-5"
              onClick={() => {
                showEditDrawer();
              }}
            >
              Edit Farm
            </Button>
            <Form layout="vertical">
              <Collapse expandIconPosition="end" className="mb-4">
                <Panel
                  header="Farm Detail"
                  key="1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Farm Name
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {!farmFormData.farm_name ? "" : farmFormData.farm_name}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Country
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {!farmFormData.countryText
                        ? "Unknown"
                        : farmFormData.countryText}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Province/State
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {!farmFormData.province_stateText
                        ? "Unknown"
                        : farmFormData.province_stateText}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      City
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.city === "unknown" || !farmFormData.city
                        ? "Unknown"
                        : farmFormData.city}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Municipal District (County)
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {!farmFormData.countyText
                        ? "Unknown"
                        : farmFormData.countyText}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Planted Area
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {` ${
                        !farmFormData.planted_area ||
                        farmFormData.planted_area === undefined
                          ? ""
                          : farmFormData.planted_area
                      } ${farmFormData.planted_area ? "Acres" : ""}`}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Postal Address
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {!farmFormData.postal_address
                        ? ""
                        : farmFormData.postal_address}
                    </Text>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition="end" className="mb-4">
                <Panel
                  header="Legal Land Description"
                  key="2"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      LLD
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.lld === "0" ? "" : farmFormData.lld}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Quarter Section
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.quarter_section === "0"
                        ? ""
                        : farmFormData.quarter_section}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Section
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.section === "0" ? "" : farmFormData.section}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Township
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.township === "0"
                        ? ""
                        : farmFormData.township}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Range
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.range === "0" ? "" : farmFormData.range}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Meridian
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {farmFormData.meridian === "0"
                        ? ""
                        : farmFormData.meridian}
                    </Text>
                  </Form.Item>
                </Panel>
              </Collapse>
            </Form>
            <div style={{ marginTop: "auto" }} key={v4()}>
              <Dropdown
                overlay={farmMenuItems}
                placement="top"
                trigger={["click"]}
                key={currentFarm}
              >
                <Button
                  style={{
                    padding: "8px",
                  }}
                  className={`justify-start h-auto w-full rounded-lg ${
                    chatMode !== "general" ? "farm-select-disabled" : null
                  }`}
                  disabled={chatMode === "general"}
                >
                  <Avatar
                    style={{ display: "flex", backgroundColor: "#f5f5f5" }}
                    className="item-center justify-center"
                    size={38}
                    icon={<Farm height={24} width={24} color="#737373" />}
                    shape="square"
                  />
                  <div
                    style={{ flex: "1 0 0" }}
                    className="flex flex-col justify-center items-start"
                  >
                    <p className="text-neutral-500 text-xs">Farms</p>
                    <h3 className="text-neutral-800 text-sm font-semibold">
                      {chatMode === "general"
                        ? "Farm name"
                        : menuItemsData?.map((farm) =>
                            farm.id === currentFarm ? farm.label : ""
                          )}
                    </h3>
                  </div>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color="#000000"
                  >
                    <path
                      d="M17 8L12 3L7 8"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M17 16L12 21L7 16"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Button>
              </Dropdown>
            </div>
          </>
        ),
        key: "Farm",
      },
      {
        label: "Field",
        children: (
          <>
            <Button
              type="primary"
              icon={<EditPencil height={24} width={24} />}
              block
              size="large"
              className="edit-field-farm-btn mb-5"
              onClick={() => {
                farmFieldData(
                  {
                    farm_field_id: currentField,
                    year: year,
                    season: season,
                  },
                  {
                    onSuccess: (data) => {
                      if (!data.data.farm_field_crops?.crop) {
                        dispatch({
                          type: "getBackUpFieldData",
                          payload: {
                            farm: currentFarm,
                            field_name: data.data.field_name,
                            soil_texture: soilTextureData?.find(
                              (soil) => soil.id === data.data.soil_type
                            )
                              ? soilTextureData?.find(
                                  (soil) => soil.id === data.data.soil_type
                                )?.type_name
                              : 0,
                            irregation_type: irregationTypesData?.find(
                              (irregation) =>
                                irregation.id === data.data.irrigation_type
                            )
                              ? irregationTypesData?.find(
                                  (irregation) =>
                                    irregation.id === data.data.irrigation_type
                                )?.type_name
                              : 0,
                            planted_area: data?.data.area_in_acre,
                            approximate_crop_height:
                              data.data?.approximate_crop_height,
                            crop_name: "",
                            crop_stage: "",
                            weeds: data.data?.farm_field_crops?.farm_field_weeds
                              ? data.data.farm_field_crops.farm_field_weeds?.map(
                                  (weed) => ({
                                    weed_name: weedsData?.find(
                                      (secWeed) => secWeed.id === weed.weed
                                    )
                                      ? weedsData?.find(
                                          (secWeed) => secWeed.id === weed.weed
                                        )?.weed_type
                                      : 0,
                                    weed_density: weedDensitiesData?.find(
                                      (density) =>
                                        density.id === weed.weed_density
                                    )
                                      ? weedDensitiesData?.find(
                                          (density) =>
                                            density.id === weed.weed_density
                                        )?.weed_density
                                      : 0,
                                    leaves_number:
                                      weed.weed_stage.number_of_leaves,
                                    height: weed.weed_stage.heihgt_in_cm,
                                    flowering: weed.weed_stage.flowering,
                                  })
                                )
                              : [],
                          },
                        });
                        showEditDrawer();
                      }

                      if (
                        data.data?.farm_field_crops?.crop &&
                        !data.data.farm_field_crops.crop_stage
                      ) {
                        dispatch({
                          type: "getBackUpFieldData",
                          payload: {
                            farm: currentFarm,
                            field_name: data.data.field_name,
                            soil_texture: soilTextureData?.find(
                              (soil) => soil.id === data.data.soil_type
                            )
                              ? soilTextureData?.find(
                                  (soil) => soil.id === data.data.soil_type
                                )?.type_name
                              : 0,
                            irregation_type: irregationTypesData?.find(
                              (irregation) =>
                                irregation.id === data.data.irrigation_type
                            )
                              ? irregationTypesData?.find(
                                  (irregation) =>
                                    irregation.id === data.data.irrigation_type
                                )?.type_name
                              : 0,
                            planted_area: data?.data.area_in_acre,
                            approximate_crop_height:
                              data.data?.approximate_crop_height,
                            crop_name: findCropById(
                              data.data.farm_field_crops?.crop
                            ),
                            crop_stage: "",
                            weeds: data.data?.farm_field_crops?.farm_field_weeds
                              ? data.data.farm_field_crops.farm_field_weeds?.map(
                                  (weed) => ({
                                    weed_name: weedsData?.find(
                                      (secWeed) => secWeed.id === weed.weed
                                    )
                                      ? weedsData?.find(
                                          (secWeed) => secWeed.id === weed.weed
                                        )?.weed_type
                                      : 0,
                                    weed_density: weedDensitiesData?.find(
                                      (density) =>
                                        density.id === weed.weed_density
                                    )
                                      ? weedDensitiesData?.find(
                                          (density) =>
                                            density.id === weed.weed_density
                                        )?.weed_density
                                      : 0,
                                    leaves_number:
                                      weed.weed_stage.number_of_leaves,
                                    height: weed.weed_stage.heihgt_in_cm,
                                    flowering: weed.weed_stage.flowering,
                                  })
                                )
                              : [],
                          },
                        });
                        showEditDrawer();
                      }

                      if (
                        data.data?.farm_field_crops?.crop &&
                        data.data.farm_field_crops?.crop_stage
                      ) {
                        cropData(
                          { crop_id: data.data?.farm_field_crops?.crop },
                          {
                            onSuccess: (seccondData) => {
                              dispatch({
                                type: "getBackUpFieldData",
                                payload: {
                                  farm: currentFarm,
                                  field_name: data.data.field_name,
                                  soil_texture: soilTextureData?.find(
                                    (soil) => soil.id === data.data.soil_type
                                  )
                                    ? soilTextureData?.find(
                                        (soil) =>
                                          soil.id === data.data.soil_type
                                      )?.type_name
                                    : 0,
                                  irregation_type: irregationTypesData?.find(
                                    (irregation) =>
                                      irregation.id ===
                                      data.data.irrigation_type
                                  )
                                    ? irregationTypesData?.find(
                                        (irregation) =>
                                          irregation.id ===
                                          data.data.irrigation_type
                                      )?.type_name
                                    : 0,
                                  planted_area: data?.data.area_in_acre,
                                  approximate_crop_height:
                                    data.data?.approximate_crop_height,
                                  crop_name: findCropById(
                                    data.data.farm_field_crops?.crop
                                  ),
                                  crop_stage: seccondData.crop_stages.find(
                                    (stage) =>
                                      stage.id ===
                                      data.data.farm_field_crops?.crop_stage
                                  )?.stage_name,
                                  weeds: data.data?.farm_field_crops
                                    ?.farm_field_weeds
                                    ? data.data.farm_field_crops.farm_field_weeds?.map(
                                        (weed) => ({
                                          weed_name: weedsData?.find(
                                            (secWeed) =>
                                              secWeed.id === weed.weed
                                          )
                                            ? weedsData?.find(
                                                (secWeed) =>
                                                  secWeed.id === weed.weed
                                              )?.weed_type
                                            : 0,
                                          weed_density: weedDensitiesData?.find(
                                            (density) =>
                                              density.id === weed.weed_density
                                          )
                                            ? weedDensitiesData?.find(
                                                (density) =>
                                                  density.id ===
                                                  weed.weed_density
                                              )?.weed_density
                                            : 0,
                                          leaves_number:
                                            weed.weed_stage.number_of_leaves,
                                          height: weed.weed_stage.heihgt_in_cm,
                                          flowering: weed.weed_stage.flowering,
                                        })
                                      )
                                    : [],
                                },
                              });
                              showEditDrawer();
                            },
                          }
                        );
                      }
                    },
                    onError: () =>
                      message.error(
                        "Something went wrong while opening modal, Please try again!"
                      ),
                  }
                );
              }}
            >
              Edit Field
            </Button>
            <Form layout="vertical">
              <Collapse expandIconPosition="end" className="mb-4">
                <Panel
                  header="Field Details"
                  key="1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Field Name
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {fieldFormData.field_name ? fieldFormData.field_name : ""}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Soil Texture
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {isSoilTextureData && errorSoilTextureData
                        ? ""
                        : !fieldFormData.soil_texture
                        ? "Unknown"
                        : soilTextureData?.map((soilTexture) =>
                            soilTexture.id === fieldFormData.soil_texture
                              ? soilTexture.type_name
                              : null
                          )}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Irrigation Type
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {isIrregationTypesData && errorIrregationTypesData
                        ? ""
                        : !fieldFormData.irregation_type
                        ? "Unknown"
                        : irregationTypesData?.map((irregationType) =>
                            irregationType.id === fieldFormData.irregation_type
                              ? irregationType.type_name
                              : null
                          )}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Planted Area
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {`${
                        !fieldFormData.planted_area
                          ? ""
                          : fieldFormData.planted_area
                      } ${fieldFormData.planted_area ? "Acres" : ""}`}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Approximate Crop Height
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {`${
                        !fieldFormData.approximate_crop_height
                          ? ""
                          : fieldFormData.approximate_crop_height
                      } ${fieldFormData.approximate_crop_height ? "CM" : ""}`}
                    </Text>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition="end" className="mb-4">
                <Panel
                  header="Corp Details"
                  key="2"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Crop Name
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {isCropGroups && errorCropGroups
                        ? ""
                        : !fieldFormData.crop_name
                        ? ""
                        : findCropById(fieldFormData.crop_name)}
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Title
                      level={5}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: "6px",
                        color: "#737373",
                      }}
                    >
                      Crop Stage
                    </Title>
                    <Text className="font-semibold text-neutral-800">
                      {!fieldFormData.crop_name
                        ? ""
                        : !fieldFormData.crop_stage
                        ? ""
                        : previewStage?.map((stage) =>
                            stage.id === fieldFormData.crop_stage
                              ? stage.stage_name
                              : null
                          )}
                    </Text>
                  </Form.Item>
                </Panel>
              </Collapse>
              <Collapse expandIconPosition="end" className="mb-4">
                <Panel
                  header="Weeds Detail"
                  key="3"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  {!fieldFormData.weeds ? (
                    <>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Weed Name
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          Dandelion
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Weed Density
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          Medium
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Number of Leaves
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          3
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Height
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          40 CM
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Flowering
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          Yes
                        </Text>
                      </Form.Item>
                    </>
                  ) : (
                    fieldFormData.weeds?.map((weed, index) => (
                      <div key={index}>
                        <Form.Item>
                          <Title
                            level={5}
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              marginBottom: "6px",
                              color: "#737373",
                            }}
                          >
                            Weed Name
                          </Title>
                          <Text className="font-semibold text-neutral-800">
                            {isWeeds || errorWeedsData
                              ? ""
                              : !weed.weed_name
                              ? "Unknown"
                              : weedsData?.map((weedType) =>
                                  weedType.id === weed.weed_name
                                    ? weedType.weed_type
                                    : null
                                )}
                          </Text>
                        </Form.Item>
                        <Form.Item>
                          <Title
                            level={5}
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              marginBottom: "6px",
                              color: "#737373",
                            }}
                          >
                            Weed Density
                          </Title>
                          <Text className="font-semibold text-neutral-800">
                            {isWeedDensities || errorWeedDensitiesData
                              ? ""
                              : !weed.weed_density
                              ? "Unknown"
                              : weedDensitiesData?.map((density) =>
                                  density.id === weed.weed_density
                                    ? density.weed_density
                                    : null
                                )}
                          </Text>
                        </Form.Item>
                        <Form.Item>
                          <Title
                            level={5}
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              marginBottom: "6px",
                              color: "#737373",
                            }}
                          >
                            Number of Leaves
                          </Title>
                          <Text className="font-semibold text-neutral-800">
                            {!weed.leaves_number ? "" : weed.leaves_number}
                          </Text>
                        </Form.Item>
                        <Form.Item>
                          <Title
                            level={5}
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              marginBottom: "6px",
                              color: "#737373",
                            }}
                          >
                            Height
                          </Title>
                          <Text className="font-semibold text-neutral-800">
                            {`${!weed.height ? "" : weed.height} ${
                              !weed.height ? "" : "CM"
                            }`}
                          </Text>
                        </Form.Item>
                        <Form.Item>
                          <Title
                            level={5}
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              marginBottom: "6px",
                              color: "#737373",
                            }}
                          >
                            Flowering
                          </Title>
                          <Text className="font-semibold text-neutral-800">
                            {weed.flowering ? "yes" : "Not yet"}
                          </Text>
                        </Form.Item>
                        {fieldFormData.weeds?.length > 0 ? (
                          index !== fieldFormData.weeds?.length - 1 ? (
                            <Divider />
                          ) : null
                        ) : null}
                      </div>
                    ))
                  )}
                </Panel>
              </Collapse>
            </Form>
            <div style={{ marginTop: "auto" }}>
              <Dropdown
                overlay={farmMenuItems}
                placement="top"
                trigger={["click"]}
              >
                <Button
                  style={{
                    padding: "8px",
                  }}
                  className={`justify-start h-auto w-full rounded-lg ${
                    chatMode !== "general" ? "farm-select-disabled" : null
                  }`}
                  disabled={chatMode === "general"}
                >
                  <Avatar
                    style={{ display: "flex", backgroundColor: "#f5f5f5" }}
                    className="item-center justify-center"
                    size={38}
                    icon={<Farm height={24} width={24} color="#737373" />}
                    shape="square"
                  />
                  <div
                    style={{ flex: "1 0 0" }}
                    className="flex flex-col justify-center items-start"
                  >
                    <p className="text-neutral-500 text-xs">Farms</p>
                    <h3 className="text-neutral-800 text-sm font-semibold">
                      {chatMode === "general"
                        ? "Farm name"
                        : farms?.map((farm) =>
                            farm.id === currentFarm ? farm.farm_name : ""
                          )}
                    </h3>
                  </div>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color="#000000"
                  >
                    <path
                      d="M17 8L12 3L7 8"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M17 16L12 21L7 16"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Button>
              </Dropdown>
            </div>
          </>
        ),
        key: "Field",
      },
    ]);
  }, [
    farmFormData,
    fieldFormData,
    select,
    itemfields,
    setSelect,
    previewStage,
  ]);

  //const [items, setItems] = useState(initialItems);

  return (
    <Drawer
      title={
        <div className="flex items-center justify-between">
          <div className="logo-vertical">
            <img className="w-32" src={BloomLogo} alt="" />
          </div>
          <Button
            style={{ height: "40px", width: "40px", padding: "10px" }}
            onClick={onClose}
          >
            {<SidebarCollapse color="#737373" height={18} width={18} />}
          </Button>
        </div>
      }
      closable={false}
      placement={"left"}
      onClose={onClose}
      open={openDrawer}
      className="chat-drawer"
    >
      {/* <Button onClick={onClose}>{<SidebarCollapse />}</Button> */}
      <Segmented
        block
        size="small"
        className="chat-segmented mb-5"
        onChange={chatModeSelectorSegment}
        value={chatMode === "general" ? "General Chat" : "Precision Chat"}
        options={[
          {
            label: "General Chat",
            value: "General Chat",
            icon: <ChatBubbleEmpty height={16} width={16} />,
          },
          {
            label: "Precision Chat",
            value: "Precision Chat",
            icon: <BubbleStar height={16} width={16} />,
          },
        ]}
      />
      {chatMode === "precision" && (
        <Tabs
          onChange={tabName}
          defaultActiveKey="1"
          centered
          items={items}
          size="large"
          style={{ height: "100%" }}
        />
      )}
      {chatMode === "general" && (
        <>
          <Button
            type="primary"
            icon={<Plus />}
            size="large"
            block
            onClick={() => {
              localStorage.removeItem("generalHash");
              navigate("/c/general");
              setSelectedKeys([]);
              setOpenDrawer(false);
            }}
            className="new-chat-btn mb-5"
          >
            New Chat
          </Button>
          <div style={{ flex: 1, overflowY: "auto" }}>
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={["1"]}
              selectedKeys={selectedKeys}
              onSelect={({ key }) => {
                setSelectedKeys([key]);
              }}
              items={leftGeneralMenuItems}
              style={{ borderInlineEnd: "0" }}
              className="left-sidebar-menu mb-5"
            />
          </div>
        </>
      )}
    </Drawer>
  );
}

export default ChatDrawer;
