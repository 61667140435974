import { useMutation } from "react-query";
import { authGoogle, google_auth } from "../../Services/apiAuth";

export function useAuthGoogle() {
  const { mutate: googleData, isLoading: isGoogling } = useMutation({
    mutationFn: authGoogle,
  });

  return {
    googleData,
    isGoogling,
  };
}

export function useGoogle() {
  const {mutate, isLoading} = useMutation({
    mutationFn: google_auth,
  });
  return {
    mutate,
    isLoading,
  }
}