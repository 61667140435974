import { useEffect, useMemo, useState } from "react";
import CounterCyrcle from "./CounterCyrcle";
import { ChatLines, EditPencil, MoreHoriz, Trash } from "iconoir-react";
import { Dropdown, Input, Menu, Modal, Space } from "antd";
import { useChat } from "../Context/ChatProvider";
import { useFarmField } from "../Features/Chat/useFarmField";
import { useNavigate } from "react-router-dom";
export default function LeftPrecisionMenu({
  itemfields,
  select,
  setSelect,
  setSelectedPKeys,
  selectedPKeys,
  setOpenDrawer,
  deletePrecisionSessionHandler,
  editPrecisionSessionHandler,
  showDeleteConfirm,
}) {
  const { dispatch, handleFieldInputChange, year, season, currentField } =
    useChat();
  const navigate = useNavigate();
  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();

  const handleOkDeleteModal = (slug) => {
    deletePrecisionSessionHandler(slug);

    setIsModalVisible(false);
  };

  useEffect(() => {
    setSelect(currentField);
  }, [currentField]);

  function ModalLabel({ text }) {
    return <p>This will delete {<span className="font-bold">{text}</span>}</p>;
  }

  document.body.onclick = () => {
    setSelectedRenaming("");
    setNewSessionTitle("");
  };

  const [newSessionTitle, setNewSessionTitle] = useState("");
  const [selectedRenaming, setSelectedRenaming] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const leftPrecisionMenuItems = useMemo(
    () =>
      itemfields?.map((field, i) => {
        return {
          key: `sub${field.feildName} ${i}`,
          icon: (
            <CounterCyrcle isActive={select === field.id ? true : false}>
              {i + 1}
            </CounterCyrcle>
          ),
          label: field.feildName,
          children: field.questionHistory.map((question, j) => {
            return {
              key: question.key,
              icon: <ChatLines />,
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setSelect(field.id);

                    if (question.question === "Start a chat...") {
                      setSelectedPKeys([question.key]);
                      localStorage.removeItem("precisionHash");
                      navigate("/c/precision");
                    } else {
                      navigate(`/c/precision/${question.key}`, {
                        replace: true,
                      });
                      localStorage.setItem("precisionHash", question.key);
                    }
                    setOpenDrawer(false);

                    dispatch({ type: "selectField", payload: field.id });
                    farmFieldData(
                      { farm_field_id: field.id, year: year, season: season },
                      {
                        onSuccess: (data) => {
                          handleFieldInputChange(
                            "field_name",
                            data.data.field_name ? data.data.field_name : ""
                          );
                          handleFieldInputChange(
                            "soil_texture",
                            data.data.soil_type ? data.data.soil_type : 0
                          );
                          handleFieldInputChange(
                            "irregation_type",
                            data.data.irrigation_type
                              ? data.data.irrigation_type
                              : 0
                          );
                          handleFieldInputChange(
                            "planted_area",
                            data.data.area_in_acre
                          );
                          handleFieldInputChange(
                            "approximate_crop_height",
                            data.data.approximate_crop_height
                          );
                          handleFieldInputChange(
                            "crop_name",
                            data.data?.farm_field_crops?.crop
                              ? data.data.farm_field_crops.crop
                              : 0
                          );
                          handleFieldInputChange(
                            "crop_stage",
                            data.data?.farm_field_crops?.crop_stage
                              ? data.data.farm_field_crops.crop_stage
                              : 0
                          );
                          handleFieldInputChange(
                            "weeds",
                            data.data?.farm_field_crops?.farm_field_weeds
                              ? data.data.farm_field_crops.farm_field_weeds.map(
                                  (weed) => ({
                                    weed_name: weed.weed,
                                    weed_density: weed.weed_density,
                                    leaves_number:
                                      weed.weed_stage.number_of_leaves,
                                    height: weed.weed_stage.heihgt_in_cm,
                                    flowering: weed.weed_stage.flowering,
                                  })
                                )
                              : []
                          );
                          handleFieldInputChange(
                            "id",
                            data.data?.farm_field_crops?.id
                              ? data.data.farm_field_crops.id
                              : 0
                          );
                        },
                      }
                    );
                  }}
                >
                  <span key={question.key}>
                    {selectedRenaming !== question.key ? (
                      question.question
                    ) : (
                      <Input
                        id="quickRenameInput"
                        onClick={(e) => e.stopPropagation()}
                        onBlur={() => setSelectedRenaming("")}
                        value={newSessionTitle}
                        onChange={(e) => setNewSessionTitle(e.target.value)}
                        className="bg-transparent"
                        onPressEnter={() => {
                          setSelectedRenaming("");
                          setNewSessionTitle("");
                          editPrecisionSessionHandler(
                            question.key,
                            newSessionTitle
                          );
                        }}
                        defaultValue={() =>
                          setNewSessionTitle(question.question)
                        }
                      />
                    )}
                  </span>
                  {question.question === "Start a chat..." ? null : (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              <Space className="p-1">
                                <EditPencil
                                  style={{
                                    height: "20",
                                    width: "20",
                                    color: "#737373",
                                  }}
                                />
                                <p>Rename</p>
                              </Space>
                            ),
                            key: "0",
                            onClick: (e) => {
                              e.domEvent.stopPropagation();
                              e.domEvent.preventDefault();
                              setSelectedRenaming(question.key);
                              setNewSessionTitle(question.question);
                              setTimeout(() => {
                                document
                                  .getElementById("quickRenameInput")
                                  ?.focus();
                              }, 40);
                            },
                          },
                          {
                            label: (
                              <>
                                <div className="flex gap-2 pl-1">
                                  <Trash
                                    style={{
                                      height: "20",
                                      width: "20",
                                      color: "#ef4444",
                                    }}
                                  />
                                  <p className="text-red-500">Delete</p>
                                </div>
                                {/* <DeleteModalComponent
                                  slug={question.key}
                                  title={question.question}
                                  open={isModalVisible}
                                  onConfirm={handleOkDeleteModal}
                                  onCancel={handleCancelDeleteModal}
                                /> */}
                              </>
                            ),
                            key: "1",
                            onClick: (e) => {
                              e.domEvent.stopPropagation();
                              e.domEvent.preventDefault();
                              setIsModalVisible(true);
                              showDeleteConfirm(
                                question.key,
                                question.question,
                                handleOkDeleteModal
                              );
                            },
                          },
                        ],
                      }}
                      trigger={["click"]}
                    >
                      <MoreHoriz
                        style={{ marginLeft: "auto", color: "#737373" }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Dropdown>
                  )}
                </div>
              ),
            };
          }),
        };
      }),
    [select, itemfields, selectedRenaming, newSessionTitle] // Update only when `select` or `fields` changes
  );

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["1"]}
      items={leftPrecisionMenuItems}
      selectedKeys={selectedPKeys}
      onSelect={({ key }) => {
        if (key.includes("new chat")) {
          localStorage.removeItem("precisionHash");
          navigate("/c/precision");
        }
        setTimeout(() => {
          setSelectedPKeys([key]);
        }, 50);
        setSelectedPKeys([key]);
      }}
      style={{ borderInlineEnd: "0" }}
      className="left-sidebar-menu mb-5"
    />
  );
}
